import { variables } from "../../variables";
import cogoToast from "cogo-toast";

const { createSlice } = require("@reduxjs/toolkit");

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    menu: [],
  },
  reducers: {
    setMenu(state, action) {
      const category = action.payload.menu;

      return (state = {
        menu: category,
      });
    },
  },
});

export const GetMenu = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        variables.API_URL + "Menus/GetAssignedItemsFrontend/2"
      );
      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();

      return result;
    } catch (error) {
      cogoToast.error(error.Message || "Something went wrong", {
        position: "bottom-left",
      });
    }
  };
};

export const { setMenu } = menuSlice.actions;
export default menuSlice.reducer;
