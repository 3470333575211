const { createSlice } = require("@reduxjs/toolkit");

const widgetSlice = createSlice({
  name: "address",
  initialState: {
    slides: [],
  },
  reducers: {
    setSlides(state, action) {
      return (state = {
        slides: action.payload.slides,
      });
    },
    clearSlides(state, action) {
      return (state = {
        slides: [],
      });
    },
  },
});

export const { setSlides, clearSlides } = widgetSlice.actions;
export default widgetSlice.reducer;
