import { variables } from "../../variables";
import cogoToast from "cogo-toast";

const { createSlice } = require("@reduxjs/toolkit");

const addressSlice = createSlice({
  name: "address",
  initialState: {
    addressBookId: 0,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    countryCode: "",
    cityId: 0,
    address: "",
  },
  reducers: {
    setAddress(state, action) {
      const addressBookId = action.payload.addressBookId;
      const firstName = action.payload.firstName;
      const lastName = action.payload.lastName;
      const phoneNumber = action.payload.phoneNumber;
      const countryCode = action.payload.countryCode;
      const cityId = action.payload.cityId;
      const address = action.payload.address;

      return (state = {
        addressBookId: addressBookId,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        countryCode: countryCode,
        cityId: cityId,
        address: address,
      });
    },
  },
});

export const getCountries = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        variables.API_URL + "Country/GetAllPublishedCountries"
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      cogoToast.error(error.Message || "Something went wrong", {
        position: "bottom-left",
      });
    }
  };
};

export const getCities = (countryCode) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        variables.API_URL +
          "Country/GetCitiesByCountryCode/CountryCode?CountryCode=" +
          countryCode
      );

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      cogoToast.error(error.Message || "Something went wrong", {
        position: "bottom-left",
      });
    }
  };
};

export const { setAddress } = addressSlice.actions;
export default addressSlice.reducer;
