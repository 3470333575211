import { Suspense, lazy, useEffect } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setCredential,
  GenerateGuestId,
  CheckTokenExpiry,
  GetCartItem,
} from "./store/slices/credential-slice";
import { setAddress } from "./store/slices/address-slice";
import { GetMenu, setMenu } from "./store/slices/menu-slice";
import { addAllToCart } from "./store/slices/cart-slice";
import {
  addAllToWishlist,
  GetWishlistItems,
} from "./store/slices/wishlist-slice";

const Home = lazy(() => import("./pages/Home"));

const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);

const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const Contact = lazy(() => import("./pages/other/Contact"));
const About = lazy(() => import("./pages/other/About"));
const EmailConfirmation = lazy(() => import("./pages/other/EmailConfirmation"));
const ForgotPassword = lazy(() => import("./pages/other/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/other/ResetPassword"));
const Unsubscribe = lazy(() => import("./pages/other/Unsubscribe"));

const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const OrderConfirmation = lazy(() => import("./pages/other/OrderConfirmation"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

function App() {
  const isLogged = useSelector((state) => state.credential.isLogged);
  const guestId = useSelector((state) => state.credential.guestId);
  const customerId = useSelector((state) => state.credential.customerId);
  const customerToken = useSelector((state) => state.credential.customerToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const offset = new Date().getTimezoneOffset();
    let result;
    let promise;

    if (!isLogged && (guestId === "" || guestId === undefined)) {
      result = dispatch(GenerateGuestId());
      dispatch(
        setCredential({
          isLogged: false,
          guestId: result,
          customerId: 0,
          customerToken: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
          email: "",
        })
      );

      //cart
      result = dispatch(GetCartItem(0, result, offset));
      promise = Promise.resolve(result);
      promise.then((value) => {
        dispatch(addAllToCart(value.Message));
      });

      //wishlist
      result = dispatch(GetWishlistItems(0, result, offset));
      promise = Promise.resolve(result);
      promise.then((value) => {
        dispatch(addAllToWishlist(value.Message));
      });
    } else if (isLogged && customerId !== 0) {
      result = dispatch(CheckTokenExpiry(customerToken, customerId));
      promise = Promise.resolve(result);

      promise.then((value) => {
        if (value.Code === 0) {
          dispatch(
            setCredential({
              isLogged: true,
              guestId: "",
              customerId: value.Message.CustomerId,
              customerToken: value.Message.CustomerToken,
              firstName: value.Message.FirstName,
              lastName: value.Message.LastName,
              phoneNumber: value.Message.PhoneNumber,
              email: value.Message.Email,
            })
          );
       
          dispatch(
            setAddress({
              addressBookId: value.Message.AddressBook.AddressBookId
                ? value.Message.AddressBook.AddressBookId
                : 0,
              firstName: value.Message.AddressBook.FirstName
                ? value.Message.AddressBook.FirstName
                : "",
              lastName: value.Message.AddressBook.LastName
                ? value.Message.AddressBook.LastName
                : "",
              phoneNumber: value.Message.AddressBook.PhoneNumber
                ? value.Message.AddressBook.PhoneNumber
                : "",
              countryCode: value.Message.AddressBook.CountryCode
                ? value.Message.AddressBook.CountryCode
                : "",
              cityId: value.Message.AddressBook.CityId
                ? value.Message.AddressBook.CityId
                : 0,
              address: value.Message.AddressBook.Address1
                ? value.Message.AddressBook.Address1
                : "",
            })
          );

          //cart
          result = dispatch(GetCartItem(value.Message.CustomerId, "", offset));
          promise = Promise.resolve(result);
          promise.then((value) => {
            dispatch(addAllToCart(value.Message));
          });

          //wishlist
          result = dispatch(
            GetWishlistItems(value.Message.CustomerId, "", offset)
          );
          promise = Promise.resolve(result);
          promise.then((value) => {
            dispatch(addAllToWishlist(value.Message));
          });
        } else {
          result = dispatch(GenerateGuestId());
          dispatch(setCredential({ isLogged: false, Id: result }));
          navigate("/");
        }
      });
    }

    result = dispatch(GetMenu());
    promise = Promise.resolve(result);

    promise.then((value) => {
      dispatch(setMenu({ menu: value }));
    });
  }, [isLogged, dispatch, guestId, customerId, navigate, customerToken]);

  return (
    <ScrollToTop>
      <Suspense
        fallback={
          <div className="flone-preloader-wrapper">
            <div className="flone-preloader">
              <span></span>
              <span></span>
            </div>
          </div>
        }
      >
        <Routes>
          <Route path={process.env.PUBLIC_URL + "/"} element={<Home />} />
          <Route
            path={process.env.PUBLIC_URL + "/product/:id"}
            element={<ProductTabLeft />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/category/:category/:id"}
            element={<ShopGridStandard />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/brand/:brand/:id"}
            element={<ShopGridStandard />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/search/:search"}
            element={<ShopGridStandard />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/login-register"}
            element={<LoginRegister />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/email-confirm/:email/:code"}
            element={<EmailConfirmation />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/contact-us"}
            element={<Contact />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/about-us"}
            element={<About />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/wishlist"}
            element={<Wishlist />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/my-account"}
            element={<MyAccount />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/forgot-password"}
            element={<ForgotPassword />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/reset-password/:email/:code"}
            element={<ResetPassword />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/unsubscribe/:email"}
            element={<Unsubscribe />}
          />
          <Route path={process.env.PUBLIC_URL + "/cart"} element={<Cart />} />
          <Route
            path={process.env.PUBLIC_URL + "/checkout"}
            element={<Checkout />}
          />
          <Route
            path={process.env.PUBLIC_URL + "/order-success/:orderNumber"}
            element={<OrderConfirmation />}
          />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </ScrollToTop>
  );
}

export default App;
