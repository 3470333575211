import cogoToast from "cogo-toast";
import { variables } from "../../variables";
const { createSlice } = require("@reduxjs/toolkit");

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    wishlistItems: [],
  },
  reducers: {
    addToWishlist(state, action) {
      const isInWishlist = state.wishlistItems.findIndex(
        (item) => item.id === action.payload.id
      );
      if (isInWishlist === -1) {
        state.wishlistItems.push(action.payload);
      } else {
      }
    },
    addAllToWishlist(state, action) {
      state.wishlistItems = action.payload;
    },
    deleteFromWishlist(state, action) {
      state.wishlistItems = state.wishlistItems.filter(
        (item) => item.id !== action.payload
      );
      cogoToast.error("Removed From Wishlist", { position: "bottom-left" });
    },
    deleteAllFromWishlist(state) {
      state.wishlistItems = [];
    },
  },
});

export const GetWishlistItems = (customerId, guestId, offset) => {
  return async (dispatch) => {
    if (customerId !== 0) {
      const response = await fetch(
        variables.API_URL +
          "Wishlist/GetWishlistItems/Offset/" +
          offset +
          "/Country/125/Group/2?CustomerId=" +
          customerId,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      return result;
    } else {
      const response = await fetch(
        variables.API_URL +
          "Wishlist/GetWishlistItems/Offset/" +
          offset +
          "/Country/125/Group/1?GuestId=" +
          guestId,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      return result;
    }
  };
};

export const {
  addToWishlist,
  addAllToWishlist,
  deleteFromWishlist,
  deleteAllFromWishlist,
} = wishlistSlice.actions;
export default wishlistSlice.reducer;
