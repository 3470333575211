const { createSlice } = require("@reduxjs/toolkit");

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orderItems: [],
  },
  reducers: {
    addOrder(state, action) {
      const product = action.payload;
      state.orderItems=product
    
    },
   
  },
});

export const {
  addOrder,
} = orderSlice.actions;
export default orderSlice.reducer;
